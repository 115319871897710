<template>
    <b-overlay :show="false" class="my-page my-portal">
        <b-row>
            <b-col cols="12" sm="12" md="3" lg="3" class="mb-2 px-2 h-100" order="last" order-md="first">
                <MyPortalCollapse class="d-none d-md-block p-0 ml-2" ref="myPortal" type="my-page"/>
                <InfoCards/>
            </b-col>

            <b-col cols="12" sm="12" md="9" lg="9" class="p-0" order="first" order-md="last">
                <b-card class="card-background ml-0 ml-md-1 mb-0" no-body>
                    <b-card-body class="content-card pb-0" style="">
                        <home v-if="pageId === 'e-manager'" />
                        <your-units v-else-if="pageId === 'your-units'" />
                        <regular-billing v-else-if="pageId === 'divider-1'" />
                        <reserve-fund-divider v-else-if="pageId === 'divider-2'" />
                        <card-traffic v-else-if="pageId === 'card-owner-1'" />
                        <open-items v-else-if="pageId === 'card-owner-2'" />
                        <reserve-fund-funds v-else-if="pageId === 'fund-1'" />
                        <!--                        <voluntary-fund v-else-if="pageId === 'fund-2'" />-->
                        <suppliers v-else-if="pageId === 'document-1'" />
                        <bulletin-board v-else-if="pageId === 'bulletin-board'" />
                        <manager-documents v-else-if="pageId === 'manager-documents'"/>
                        <readings v-else-if="pageId === 'readings'"/>
                        <bulletin v-else-if="pageId === 'bulletin-board-1'" />
                        <notifications v-else-if="pageId === 'notification'" />
                        <notification v-else-if="pageId === 'notification-1'" />
                        <electronic-document-signing v-else-if="pageId === 'notification-2'" />
                        <meetings v-else-if="pageId === 'meeting'" />
                        <jitsi-meeting v-else-if="pageId === 'meeting-1'" />
                        <!-- <meetings v-else-if="pageId === 'meeting'"/> -->
                        <!-- <meeting v-else-if="pageId === 'meeting-1'"/> -->
                        <agent-messages v-else-if="pageId === 'agent-message'" />
                        <coupons v-else-if="pageId === 'coupon'" />
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
    import {
        BOverlay,
        BRow,
        BCol,
        VBTogglePlugin,
        // BSidebar,
        // BButton,
        BCard,
        BCardBody
    } from 'bootstrap-vue'
    // import home from './Home'
    // import yourUnits from './YourUnits'
    // import regularBilling from './RegularBilling'
    // import ReserveFundDivider from './ReserveFundDivider.vue'
    // import cardTraffic from './CardTraffic.vue'
    // import openItems from './OpenItems.vue'
    // import reserveFundFunds from './ReserveFundFunds.vue'
    // import voluntaryFund from './VoluntaryFund.vue'
    // import suppliers from './Suppliers.vue'
    // import bulletinBoard from './BulletinBoard.vue'
    // import bulletin from './Bulletin.vue'
    // import notifications from './Notifications.vue'
    // import notification from './Notification.vue'
    // import electronicDocumentSigning from './ElectronicDocumentSigning.vue'
    // import meetings from './Meetings.vue'
    // import meeting from './Meeting'
    // import agentMessages from './AgentMessages.vue'
    // import coupons from './Coupons.vue'
    // import MyPortalCollapse from '@/views/Pages/MyPortal/MyPortalCollapse'
    // import JitsiRooms from '../Jitsi/JitsiRooms.vue'
    // import JitsiMeeting from '../Jitsi/JitsiMeeting.vue'
    import InfoCards from '@/views/Pages/MyPortal/InfoCards'
    import Vue from 'vue'
    Vue.use(VBTogglePlugin)
    export default {
        components: {
            BCard,
            InfoCards,
            BOverlay,
            BRow,
            BCol,
            // BSidebar,
            // BButton,
            BCardBody,
            MyPortalCollapse: () => import('@/views/Pages/MyPortal/MyPortalCollapse'),
            home: () => import('./Home'),
            yourUnits: () => import('./YourUnits'),
            regularBilling: () => import('./RegularBilling'),
            ReserveFundDivider: () => import('./ReserveFundDivider.vue'),
            cardTraffic: () => import('./CardTraffic.vue'),
            openItems: () => import('./OpenItems.vue'),
            reserveFundFunds: () => import('./ReserveFundFunds.vue'),
            //voluntaryFund: () => import('./VoluntaryFund.vue'),
            suppliers: () => import('./Suppliers.vue'),
            managerDocuments: () => import('@/views/Pages/MyPortal/ManagerDocuments'),
            readings: () => import('./Readings'),
            bulletinBoard: () => import('./BulletinBoard.vue'),
            bulletin: () => import('./Bulletin.vue'),
            notifications: () => import('./Notifications.vue'),
            notification: () => import('./Notification.vue'),
            electronicDocumentSigning: () => import('./ElectronicDocumentSigning.vue'),
            // meetings,
            // meeting,
            agentMessages: () => import('./AgentMessages.vue'),
            coupons: () => import('./Coupons.vue'),
            Meetings: () => import('@/views/Pages/MyPortal/Meetings.vue'),
            JitsiMeeting: () => import('../Jitsi/JitsiMeeting.vue')
        },
        data() {
            return {
                pageId: this.type,
                home: true,
                created: false,
                emailConfirmed: false
            }
        },
        methods: {
            async checkEmailConfirmation() {

                try {
                    const res = await this.$http.get('/api/user/v1/check_email_confirmation')
                    this.emailConfirmed = res.data.email_confirmed
                } catch (err) {
                    this.$printError('Prislo je do napake')
                }
            }
        },
        async mounted() {
            await this.checkEmailConfirmation()
            if (!this.emailConfirmed) {
                this.$router.push({name: 'error-404'})
            }


            if (this.$route.meta.pageId === -2) return
            this.pageId = this.$route.meta.pageId
            this.home = true

        },
        watch: {
            '$route.meta.pageId'(val) {
                if (val === -2) {
                    this.home = true
                    return
                }
                this.pageId = val
                this.$refs.myPortal.myPortalActiveId = val
                this.home = false
            }
        }
    }
</script>

<style scoped>
#sidebar-1.bg-light {
  background-color: #e6e6e6 !important;
}

.card-background {
  margin-right: -1000px !important;
  background: linear-gradient(180deg, rgba(234,243,249,1) 0%,rgba(234,243,249,1) 85%, rgba(255,255,255,1) 97%);
  border-radius: 10px;
  height: 100% !important;
}

@media only screen and (max-width: 768px) {
  .card-background {
    border-radius: 0px !important;
  }
}
.card {
  box-shadow: none !important;
}

@media only screen and (min-width: 768px) {
  .content-card {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
    }
}
.content-card {
    /* blue background goes way beyond into right. we move parent div 1000px to right, apply background and move it back */
    margin-right: 1000px; 
    border-radius: 10px !important;
    padding-left: 0.75em;
    padding-right: 0.75em;
}
</style>
<style>
.my-portal .card {
  border-radius: 10px;
}

</style>
