<template>
    <div>
        <!--  VAŠ UPRAVNIK -->
        <div v-if="manager && manager.id==='staninvest'" class="mt-1">
            <div class="mb-0 pl-1 pl-md-1 pb-0 card-header">
                <div class="w-100 header primary-color">Vaš upravnik</div>
            </div>
            <div class="ml-1 ml-md-1 py-1 content">
                <h4 v-if="manager.id !== 'habit'" class="mb-0 font">{{manager.name}}</h4>
                <p v-if="manager.title !== ''" class="long-text mb-0 mt-0">{{manager.title}}</p>
                <p class="long-text mt-0">{{manager.address}}</p>
                <div class="d-flex align-items-center">
                    <h5 class="long-text m-0" style="padding-top: 1px !important; padding-right: 1rem;"><fa style="width: 16px; height: 16px;" class="primary-color text-secondary" icon="phone-alt" size="2x"/></h5>
                    <a :href="'tel:' + manager.phone"  class="long-text m-0" style="word-break: break-all; color: black; font-weight: bold;">
                        {{manager.phone}}
                    </a>
                </div>
                <div class="d-flex align-items-center" style="margin-top: 3px;">
                    <h5 class="long-text m-0" style="padding-top: 1px !important; padding-right: 1rem;"><fa style="width: 16px; height: 16px;" class="primary-color text-secondary" icon="envelope" size="2x"/></h5>
                    <a :href="'mailto:' + manager.email" class="long-text m-0" style="word-break: break-all; color: black;">
                        {{manager.email}}
                    </a>
                </div>
                <a target="_blank" :href="manager.website"><round-button class="mt-1" fontSize='15px' padding="0.5em 1.5em">{{manager.website.replace('https://', '')}}</round-button></a>
            </div>
        </div>
        <div v-if="manager && manager.id==='habit'" class="mt-1">
            <div class="mb-0 pl-1 pl-md-1 pb-0 card-header">
                <div class="w-100 header primary-color">Vaš upravnik</div>
                <h4 v-if="manager.title !== ''" class="mb-1 font">{{manager.title}}</h4>
            </div>
            <div v-for="(item,index) in manager.array_pe" :key="index" class="ml-1 ml-md-1 py-1 content">
                <h4 class="mb-0 font">{{item.name}}</h4>
                <!--                <p class="long-text mt-0">{{item.address}}</p>
                <div class="d-flex align-items-center">
                    <h5 class="long-text m-0" style="padding-top: 1px !important; padding-right: 1rem;"><fa style="width: 16px; height: 16px;" class="primary-color text-secondary" icon="phone-alt" size="2x"/></h5>
                    <a :href="'tel:' + item.phone"  class="long-text m-0" style="word-break: break-all; color: black; font-weight: bold;">
                        {{item.phone}}
                    </a>
                </div>-->
                <div class="d-flex align-items-center" style="margin-top: 3px;">
                    <h5 class="long-text m-0" style="padding-top: 1px !important; padding-right: 1rem;"><fa style="width: 16px; height: 16px;" class="primary-color text-secondary" icon="envelope" size="2x"/></h5>
                    <a :href="'mailto:' + item.email" class="long-text m-0" style="word-break: break-all; color: black;">
                        {{item.email}}
                    </a>
                </div>
            </div>
            <a target="_blank" :href="manager.website"><round-button class="mt-1" fontSize='15px' padding="0.5em 1.5em">{{manager.website.replace('https://', '')}}</round-button></a>
        </div>
        <!-- VKLOPITE E-RAČUN -->
        <div class="mt-1">
            <div class="mb-0 pl-1 pl-md-1 pb-0 card-header">
                <span class="w-100 header">Vklopite e-račun</span>
            </div>
            <div class="ml-1 ml-md-1 py-1 content">
                <h4 class="font">E-račun na e-naslov</h4>
                <p class="long-text">
                    E-račun je brezplačen<br>
                    in prijaznejši do okolja
                </p>
                <round-button @click="$router.push({name: 'agent_messages'}); $scrollToTop()">Uredi</round-button>
            </div>
        </div>

        <!-- NALOŽITE MOBILNO APLIKACIJO -->
        <!-- <div class="mt-1">
            <div class="mb-0 pl-1 pl-md-1 pb-0 card-header">
                <span class="w-100 header">Naloži mobilno aplikacijo</span>
            </div>
            <b-row class="ml-1 ml-md-1 p-1 content d-flex align-items-center flex-wrap justify-content-between">
                <b-col class="p-0 m-0">
                    <b-img fluid :src="require('@/assets/images/esoseska/download-app-hand-holding-phone.png')"/>
                </b-col>
                <b-col class="p-0 m-0">
                    <b-img fluid :src="require('@/assets/images/esoseska/google_play.png')"/>
                    <b-img class="mt-2" fluid :src="require('@/assets/images/esoseska/app_store.png')"/>
                </b-col>
            </b-row>
        </div> -->
    </div>
</template>

<script>
    import RoundButton from '@/views/Components/RoundedButton.vue'
    export default {
        components: {RoundButton},
        data() {
            return {
                manager: null
            }
        },
        methods: {
            getManager() {
                if (this.$store.state.user.userData && this.$store.state.user.userData.manager !== '') {
                    if (this.$store.state.user.userData.manager === 'staninvest') this.manager = this.$store.state.app.managers[0]
                    else this.manager = this.$store.state.app.managers[1]
                }
            }
        },
        mounted() {
            this.getManager()
        }
    }
</script>

<style scoped>
.card-header {
background-color: white;
border: none !important;
}
.card-header .header {
    color: #72A5D8;
    font-size: 2.5em;
    font-weight: bolder;
}
.content {
    border-top: 1px solid black;
}
.primary-color {
    color: #72A5D8 !important
}
.long-text{
    font-size: 15px;
    line-height: 22px;
    margin-top: 0.5em;
}
</style>
